import { useCallback } from 'react';

export const useCompareDate = (fechaDadaStr) => {
  const esFutura = useCallback((fechaDadaStr) => {
    // Obtener la fecha actual en formato string
    const hoy = new Date();
    const fechaActualStr = hoy.toISOString().split('T')[0];

    // Convertir a objetos Date para comparar
    const fechaDada = new Date(fechaDadaStr + 'T00:00:00');
    const fechaActual = new Date(fechaActualStr + 'T00:00:00');

    // Determinar si la fecha dada es futura y devolver resultado
    return fechaDada > fechaActual;
  }, []);

  return esFutura;
};