  // // src/hooks/useFetchCredits.js - custom hook
  import axios from 'axios';
  import {useEffect, useCallback} from 'react';
  import { useRecoilState } from 'recoil';
  import { userCreditsState } from '../../state';
  import { fetchAuthSession } from 'aws-amplify/auth';

  export const useFetchUserCredits = (CompanyID) => {
    const [, setUserCredits] = useRecoilState(userCreditsState);

    const fetchCredits = useCallback(async () => {
      try {
        const session = await fetchAuthSession();
        const authToken = session.tokens.accessToken.toString();
        const response = await axios.post('https://mk92pckgt5.execute-api.us-east-1.amazonaws.com/prod/credits', { CompanyID: CompanyID }, { headers: { Authorization: authToken, 'Content-Type': 'application/json' } });
        setUserCredits(response.data);
      } catch (error) {
        console.error('Error al obtener los Códigos disponibles:', error);
      }
    }, [CompanyID, setUserCredits]);
  
    useEffect(() => {
      fetchCredits();
    }, [fetchCredits]);

    // Retorna directamente la función para ser usada por los componentes
    return fetchCredits;
  };
