import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useRecoilState } from 'recoil';
import { userAttributesState, excelDataState, userCreditsState } from '../../state';
// import logo_blue from '../../assets/images/Authencode.svg'
import logo_blue from '../../assets/images/Authencode.png'
// import { useNavigate } from 'react-router-dom';

const SiteNav = (props) => {
    const [userAttributes, setUserAttributes] = useRecoilState(userAttributesState);
    const [, setExcelData] = useRecoilState(excelDataState);
    const [, setUserCredits] = useRecoilState(userCreditsState);
    // const navigate = useNavigate();

    const handleLogout = () => {
        setUserAttributes(null)
        setExcelData([])
        setUserCredits(null)
        props.logOut()
        sessionStorage.removeItem('fileList')
        sessionStorage.removeItem('selectedRowKeys')
        sessionStorage.removeItem('userAttributesState')
        sessionStorage.removeItem('excelDataState')
        // Redirige al usuario a la ruta raíz
        // navigate('/');
        window.location.href = '/'
    }
    if (!!userAttributes['email'] && (userAttributes['custom:Rol'] === 'verificador')) {
        return (
            <header>
                <Navbar className='mb-2' expand="lg" bg="dark" data-bs-theme="dark">
                    <Container>
                        <Navbar.Brand href="/" style={{paddingBottom:0}}>
                            <img
                                alt="Logo Authencode"
                                src={logo_blue}
                                // width="30"
                                height="50"
                                className="d-inline-block align-middle"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                            <Nav className="me-2">

                                <Nav.Link href='/'>Perfil</Nav.Link>
                                <Nav.Link href='/generar'>Generar</Nav.Link>
                                <Nav.Link href='/autorizar'>Autorizar</Nav.Link>

                                <Nav.Link onClick={handleLogout}>Cerrar Sesión</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    } else {
        return (
            <header>
                <Navbar className='mb-2' expand="lg" bg="dark" data-bs-theme="dark">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src={logo_blue}
                                // width="30"
                                height="50"
                                className="d-inline-block align-middle"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                            <Nav className="me-2">

                                <Nav.Link href='/'>Perfil</Nav.Link>
                                <Nav.Link href='/generar'>Generar</Nav.Link>

                                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

};

export default SiteNav;