// src/pages/GenerateCodes.js
import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userAttributesState, userCreditsState } from '../../state';
import { Table, Button, message, Spin } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { excelDataState } from '../../state';
import FileUpload from '../../components/fileUpload/FileUpload';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useFetchUserCredits } from '../../hooks/useFetchCredits/useFetchCredits';
import { useCompareDate } from '../../hooks/useCompareDate/useCompareDate'
import 'bootstrap/dist/css/bootstrap.min.css'

const GenerateCodes = () => {
  const [excelData, setExcelData] = useRecoilState(excelDataState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userAttributes,] = useRecoilState(userAttributesState);
  const CompanyID = userAttributes['custom:CompanyID'];
  const fetchUserCredits = useFetchUserCredits(CompanyID);
  const [userCredits,] = useRecoilState(userCreditsState);
  if (!userCredits) {
    fetchUserCredits();
  }
  const [isLoading, setIsLoading] = useState(false);
  const compararFecha = useCompareDate();
  // Recuperar el estado desde sessionStorage cuando el componente se monta
  useEffect(() => {
    const savedSelectedRowKeys = sessionStorage.getItem('selectedRowKeys');
    if (savedSelectedRowKeys && JSON.parse(savedSelectedRowKeys).length > 0) {
      setSelectedRowKeys(JSON.parse(savedSelectedRowKeys));
    }
  }, []);
  // Guardar el estado en sessionStorage cuando cambia
  useEffect(() => {
    sessionStorage.setItem('selectedRowKeys', JSON.stringify(selectedRowKeys));
  }, [selectedRowKeys]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = excelData.length > 0 ? Object.keys(excelData[0]).map(key => ({
    title: key,
    dataIndex: key,
    key: key,
  })) : [];

  const encryptAndAddData = async () => {
    const selectedRows = excelData.filter(data => selectedRowKeys.includes(data.ordinal));

    // Verificar si alguno de los selectedRows ya tiene un Codigo_A generado
    const yaGenerado = selectedRows.some(row => row.Codigo_A);

    if (yaGenerado) {
      message.warning('Uno o más de los registros seleccionados ya tienen un código generado.');
      return;
    }

    const session = await fetchAuthSession();
    const authToken = session.tokens.accessToken.toString();
    //codigo para verificar la vigencia de los codigos
    if (compararFecha(userCredits['0'].CreditsExpiry)) {
      try {
        setIsLoading(true); // Activa el spinner
        const encryptedDataResponses = await Promise.all(selectedRows.map(async (row) => {
          const { ordinal, ...rowData } = row;
          const CompanyID = userAttributes['custom:CompanyID'];
          const response = await axios.post('https://mk92pckgt5.execute-api.us-east-1.amazonaws.com/prod/encrypt', { CompanyID: CompanyID, ordinal, data: rowData }, { headers: { Authorization: authToken, 'Content-Type': 'application/json' } });
          return {
            ...row,
            'Identificador Temporal': response.data.data.codeID,
            Codigo_A: response.data.data.codeA,
            Codigo_B: response.data.data.codeB,
          };
        }));
        const updatedData = excelData.map(dataRow => {
          const foundEncryptedRow = encryptedDataResponses.find(encryptedRow => encryptedRow.ordinal === dataRow.ordinal);
          return foundEncryptedRow ? { ...dataRow, ...foundEncryptedRow } : dataRow;
        });
        console.log(updatedData)
        setExcelData(updatedData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error during encryption:', error);
        setIsLoading(false);
      }
    } else {
      message.info('Los codigos actuales se encuentran expirados.')
    }
  };

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success('Se ha exportado la información correctamente')
  };

  const handleClearData = () => {
    setExcelData([]);
    setSelectedRowKeys([]);
  };

  return (
    <Spin spinning={isLoading} delay={500} tip="Generando Codigos...">
      <div className='me-3 ms-3'>
        <h2>Generar Códigos de Verificación</h2>
        <FileUpload onClearData={handleClearData} />
        <Button onClick={encryptAndAddData} disabled={selectedRowKeys.length === 0} style={{ fontFamily: 'Rubik' }}>
          Generar Codigos
        </Button>
        <Button
          onClick={() => exportToExcel(excelData, 'datos_exportados')}
          disabled={selectedRowKeys.length === 0}
          style={{ marginLeft: 8, marginRight: 8, fontFamily: 'Rubik' }}
        >
          Exportar a Excel
        </Button>
        <Table
          rowKey="ordinal"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={excelData}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </Spin>
  );
};

export default GenerateCodes;
