// state.js
import { atom } from 'recoil';

export const sessionStorageEffect = key => ({setSelf, onSet}) => {
  // Carga el estado inicial de sessionStorage
  const savedValue = sessionStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  // Suscribe a cambios en el estado y actualiza sessionStorage
  onSet(newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const excelDataState = atom({
  key: 'excelDataState',
  default: [],
  effects_UNSTABLE: [
    sessionStorageEffect('excelDataState')
  ]
});

export const userAttributesState = atom({
  key: 'userAttributesState',
  default: null,
  effects_UNSTABLE: [
    sessionStorageEffect('userAttributesState')
  ]
});

export const userCreditsState = atom({
  key: 'userCreditsState',
  default: null, // Un valor por defecto, como 0
  effects_UNSTABLE: [
    sessionStorageEffect('userCreditsState')
  ]
});