import React from 'react';
import Container from 'react-bootstrap/esm/Container';

const SiteFooter = () => {
    return (
        <footer className='py-3 mt-auto'>
            <Container className='px-4'>
                <p className='text-center'><strong>&copy;</strong> Ingenieria Creativa 2024. Todos los derechos reservados</p>
            </Container>
        </footer>
    );
};

export default SiteFooter;