// src/pages/profile.js
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAttributesState, userCreditsState } from '../../state';
import { AccountSettings } from '@aws-amplify/ui-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

import { useFetchUserCredits } from '../../hooks/useFetchCredits/useFetchCredits';

import { message } from 'antd';

const Profile = () => {
  const [userAttributes,] = useRecoilState(userAttributesState);
  const CompanyID = userAttributes['custom:CompanyID'];
  const fetchUserCredits = useFetchUserCredits(CompanyID); // Hook modificado que ahora devuelve una función

  const [userCredits,] = useRecoilState(userCreditsState);

  if (!userCredits){
    fetchUserCredits();
  }
  const attributeLabels = {
    "custom:Nombre": "Nombre",
    "custom:Apellidos": "Apellidos",
    "email": "Correo Electrónico",
    "custom:Institucion": "Institución",
  };

  const components = {

    CurrentPasswordField: (props) => (
      <AccountSettings.ChangePassword.CurrentPasswordField
        {...props}
        label="Contraseña actual"
      />
    ),
    NewPasswordField: (props) => (
      <AccountSettings.ChangePassword.NewPasswordField
        {...props}
        label="Nueva contraseña"
      />
    ),
    ConfirmPasswordField: (props) => (
      <AccountSettings.ChangePassword.ConfirmPasswordField
        {...props}
        label="Confirmar nueva contraseña"
      />
    ),
    SubmitButton: (props) => (
      <AccountSettings.ChangePassword.SubmitButton {...props}>
        Cambiar Contraseña
      </AccountSettings.ChangePassword.SubmitButton>
    )
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSuccess = () => {
    message.info('La contraseña se ha cambiado con éxito!');
    handleClose();
  };
  return (
    <div className='me-3 ms-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: '10vh', height: 'auto' }}>
      <Card style={{ width: 'auto', overflowWrap: 'anywhere', minwidth:'0'}}>
        <Card.Header as='h3'>Perfil del Usuario</Card.Header>
        <ListGroup variant="flush">
          {Object.entries(attributeLabels).map(([key, label]) => {
            const value = userAttributes[key];
            return value ? (
              <ListGroup.Item key={key} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px',alignItems: 'center' }}>
                <div><strong>{label}</strong></div>
                <div>{value}</div>
              </ListGroup.Item>
            ) : null;
          })}
          <ListGroup.Item key={'credits'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' ,alignItems: 'center'}}>
            <div><strong>Códigos restantes</strong></div>
            <div>{!userCredits ? <Spinner animation="border" variant="primary" size="sm" /> : userCredits['0'].Credits}</div>
          </ListGroup.Item>
          <ListGroup.Item key={'change_psw'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' ,alignItems: 'center'}}>
            <div><strong>Cambiar Contraseña</strong></div>
            <div>
              <Button variant="primary" onClick={handleShow} size='sm' width='50%'>
                Cambiar Contraseña
              </Button>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Cambio de Contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AccountSettings.ChangePassword
                    onSuccess={handleSuccess}
                    components={components}
                  />
                </Modal.Body>
              </Modal>
            </div>

          </ListGroup.Item>
          <ListGroup.Item key={'CreditsExpiry'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' ,alignItems: 'center' }}>
            <div><strong>Creditos Expiran en</strong></div>
            <div>{!userCredits ? <Spinner animation="border" variant="primary" size="sm" /> : userCredits['0'].CreditsExpiry}</div>
          </ListGroup.Item>

          <ListGroup.Item key={'SubscriptionStart'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' ,alignItems: 'center' }}>
            <div><strong>Inicio de la suscripción</strong></div>
            <div>{!userCredits ? <Spinner animation="border" variant="primary" size="sm" /> : userCredits['0'].SubscriptionStart}</div>
          </ListGroup.Item>

          <ListGroup.Item key={'SubscriptionEnd'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' ,alignItems: 'center' }}>
            <div><strong>Fin de la suscripción</strong></div>
            <div>{!userCredits ? <Spinner animation="border" variant="primary" size="sm" /> : userCredits['0'].SubscriptionEnd}</div>
          </ListGroup.Item>

        </ListGroup>
      </Card>

    </div>
  );
};

export default Profile;
