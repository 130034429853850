import React from 'react';
import { Navigate , Outlet} from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, redirectPath, children}) => {
    if (!isAllowed) {
        if (!!redirectPath){
            redirectPath='/'
        }
        return <Navigate to = {redirectPath} replace  />;
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute