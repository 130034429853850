// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAttributesState} from './state';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes } from 'aws-amplify/auth'
import { withAuthenticator, translations } from '@aws-amplify/ui-react';
import { sessionStorage, I18n } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import awsExports from './aws-exports';
import SiteNav from './components/common/SiteNav';
import SiteFooter from './components/common/SiteFooter';
import { Spin } from 'antd';
import GenerateCodes from './pages/GenerateCodes/GenerateCodes';
import AuthorizeCodes from './pages/AuthorizeCodes/AuthorizeCodes';
import Profile from './pages/Profile/profile';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import '@aws-amplify/ui-react/styles.css';
import logo from './assets/images/logo_carbon.svg'

Amplify.configure(awsExports);
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

I18n.putVocabularies(translations);
I18n.setLanguage('es'); // Para español

I18n.putVocabulariesForLanguage("es", {
  "Reset Password": "Restablecer Contraseña",
  "Enter your email": "Ingrese su email",
  "Code *": "Código *",
  });
  
function App({ signOut }) {

  const [userAttributes, setUserAttributes] = useRecoilState(userAttributesState);

  useEffect(() => {
      async function retrieveCurrentUser() {
        try {
          const attributes = await fetchUserAttributes();
          setUserAttributes(attributes); // Actualiza el estado global de Recoil con los atributos del usuario
        } catch (err) {
          console.error("Error al obtener el usuario autenticado:", err);
        }
      }
      retrieveCurrentUser();
  }, [setUserAttributes]);

  return (
    !userAttributes ?
      <>
        <Spin size='large' delay={500} fullscreen />
      </>
      :
      <div className="app-container" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <SiteNav logOut={signOut} />
        <main style={{ minheight: 'calc(100vh - 60px)', flex: 1 }}>
          <Router>
            <Routes>
              <Route element={<ProtectedRoute isAllowed={!!userAttributes['email']} />} >
                <Route path="/generar" element={<GenerateCodes />} />
                <Route path="/perfil" element={<Profile />} />
                <Route path="/" element={<Profile />} />
              </Route>
              <Route path="/autorizar" element={
                <ProtectedRoute redirectPath="/perfil" isAllowed={!!userAttributes['email'] && (userAttributes['custom:Rol'] === 'verificador')} >
                  <AuthorizeCodes />
                </ProtectedRoute>
              }
              />
            </Routes>
          </Router>  
        </main>
        <SiteFooter style={{ height: '60px' }} />
      </div>
  );
}

const MyHeader = () => (
  <div style={{ textAlign: 'center', paddingTop: '2rem',paddingBottom: '1rem' }}>
    <img src={logo} alt="Logo" style={{ height: '60px' }} />
  </div>
);

const MyFooter = () => (
  <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
    <p><strong>&copy;</strong> Ingenieria Creativa 2024. Todos los derechos reservados.</p>
  </div>
);

export default withAuthenticator(App, { hideSignUp: true, components: { Header: MyHeader, Footer: MyFooter }, variation: 'default' });