// src/pages/AuthorizeCodes.js
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAttributesState, userCreditsState } from '../../state';
import { Table, Button, message, Checkbox, Spin, Modal } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useFetchUserCredits } from '../../hooks/useFetchCredits/useFetchCredits';
import { useCompareDate } from '../../hooks/useCompareDate/useCompareDate'
import 'bootstrap/dist/css/bootstrap.min.css'

const ActivateCodes = () => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [userAttributes,] = useRecoilState(userAttributesState);
    const CompanyID = userAttributes['custom:CompanyID'];
    const fetchUserCredits = useFetchUserCredits(CompanyID);
    const [userCredits,] = useRecoilState(userCreditsState);
    if (!userCredits) {
        fetchUserCredits();
    }
    //Estado inicial para no mostrar el Spin y el mensaje
    const [loadingState, setLoadingState] = useState({ isLoading: false, message: '' });
    const compararFecha = useCompareDate();
    const fetchCodes = async () => {
        setLoadingState({ isLoading: true, message: 'Consultando códigos...' });
        // Limpiar los datos actuales y la selección
        setData([]);
        setSelectedRowKeys([]);
        try {
            const session = await fetchAuthSession();
            const authToken = session.tokens.accessToken.toString();
            const response = await axios.post('https://mk92pckgt5.execute-api.us-east-1.amazonaws.com/prod/codes', { CompanyID: CompanyID }, { headers: { Authorization: authToken, 'Content-Type': 'application/json' } });
            setData(response.data);
            // Si todo es exitoso, limpia el estado de carga al final
            setLoadingState({ isLoading: false, message: '' });
        } catch (error) {
            message.error('Error al consultar los códigos');
            setLoadingState({ isLoading: false, message: '' });
        }
    };

    const activateCodes = async () => {
        // Primero muestra el modal de confirmación
        Modal.confirm({
            title: '¿Está seguro de que desea activar los códigos seleccionados?',
            content: 'Esta acción no se puede deshacer.',
            onOk: async () => {
                try {
                    const session = await fetchAuthSession();
                    const authToken = session.tokens.accessToken.toString();
                    // Verificar si alguno de los elementos seleccionados ya está activo
                    const isAnySelectedActive = data.some(item => selectedRowKeys.includes(item.DataID) && item.Activo);

                    if (isAnySelectedActive) {
                        message.warning('Algunos de los códigos seleccionados ya están activos.');
                        return;
                    }
                    const itemsToActivate = selectedRowKeys.map(key => ({ CompanyID: CompanyID, DataID: key }));
                    if (itemsToActivate.length <= Number(userCredits['0'].Credits) && compararFecha(userCredits['0'].CreditsExpiry)) {
                        setLoadingState({ isLoading: true, message: 'Activando códigos...' });
                        const response = await axios.post('https://mk92pckgt5.execute-api.us-east-1.amazonaws.com/prod/updatecodes', itemsToActivate, { headers: { Authorization: authToken, 'Content-Type': 'application/json' } });
                        if (response.data && Array.isArray(response.data)) {
                            response.data.forEach(responseItem => {
                                if (responseItem.status === 'fulfilled') {
                                    const tableItem = data.find(item => item.DataID === responseItem.DataID);
                                    if (tableItem) {
                                        tableItem.Activo = true;
                                        tableItem.TimestampCode = responseItem.TimestampCode;
                                        tableItem.codeID="";
                                        tableItem.Eliminar=null;
                                        message.success(`Código ${responseItem.DataID} activado con éxito`);
                                    }
                                } else if (responseItem.status === 'rejected') {
                                    message.error(`Código ${responseItem.DataID} no pudo ser activado: ${responseItem.reason}`);
                                }
                            });
                            fetchUserCredits();
                            setData([...data]);
                        } else {
                            message.error('Respuesta inesperada del servidor');
                        }
                        setLoadingState({ isLoading: false, message: '' });
                    } else {
                        message.error('No tiene suficientes códigos en su suscripción para realizar la activación solicitada, o se encuentran expirados.')
                    }
                } catch (error) {
                    message.error('Error al activar los códigos: ' + error.message);
                    setLoadingState({ isLoading: false, message: '' });
                }
            },
            onCancel() {
                console.log('Cancelado por el usuario');
            },
        });
    };

    const columns = [
        {
            title: 'Identificador Temporal',
            dataIndex: 'codeID',
            key: 'codeID',
        },
        {
            title: 'Código A',
            dataIndex: 'DataID',
            key: 'DataID',
        },
        {
            title: 'Fecha/Hora Creación',
            dataIndex: 'TimestampCode',
            key: 'TimestampCode',
            render: timestamp => moment(timestamp).tz('America/Bogota').format('DD/MMM/YYYY hh:mm:ss a')
        },
        {
            title: 'Fecha/Hora Vencimiento',
            dataIndex: 'Eliminar',
            key: 'Eliminar',
            render: timestamp => timestamp ? moment.unix(timestamp).format('DD/MMM/YYYY hh:mm:ss a') : '' 
        },
        {
            title: 'Activo',
            dataIndex: 'Activo',
            key: 'Activo',
            render: Activo => (
                <Checkbox checked={Activo} disabled />
            )
        }
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    return (
        <Spin spinning={loadingState.isLoading} delay={500} tip={loadingState.message}>
            <div className='ms-3 me-3'>
                <h2>Activar Códigos de Verificación</h2>
                <Button onClick={fetchCodes}>Consultar Códigos</Button>
                <Button onClick={activateCodes} disabled={!selectedRowKeys.length} style={{ marginLeft: 8, marginRight: 8 }}>Activar Códigos</Button>
                <Table rowSelection={rowSelection} columns={columns} dataSource={data} pagination={false} style={{ marginTop: 16 }} rowKey="DataID" />
            </div>
        </Spin>
    );
};

export default ActivateCodes;
